import React, { useState, useEffect } from "react";
import "./UniquePopup.css";
import { getImage } from '../../utils/getImage';

// Fetching images dynamically using getImage function
const banerImg = getImage('Claim-offer.png');

const UniquePopup = () => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        // Automatically open the popup 10 seconds after the component mounts
        const timer = setTimeout(() => {
            setIsOpen(true);
            // playSound();  // Play sound on popup open
        }, 10000);

        return () => clearTimeout(timer);
    }, []);

    const handleClose = () => {
        setIsOpen(false);
    };

    // Function to play attention-grabbing sound
    // const playSound = () => {
    //     const audio = new Audio("/sounds/notification.mp3"); // Ensure you place sound file inside public/sounds folder
    //     audio.play();
    // };

    return (
        isOpen && (
            <div className="unique-popup">
                <div className="unique-popup__backdrop"></div>       
                <div className="unique-popup__content position-relative">
    {/* Close Icon - Top Right */}
    <svg
        className="position-absolute top-0 end-0 m-2 p-1 bg-white rounded-circle shadow cursor-pointer"
        onClick={handleClose}
        viewBox="0 0 24 24"
        aria-hidden="true"
        width="24"
        height="24"
    >
        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
    </svg>

    {/* Clickable Image */}
    <a href="https://wa.link/hd4mvx" target="_blank" rel="noopener noreferrer">
        <img title="offer" alt="banner_image" src={banerImg} className="unique-popup__image"/>
    </a>
</div>

            </div>
        )
    );
};

export default UniquePopup;
