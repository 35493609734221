import React, { useState, useEffect, useRef } from "react";
import { QRCodeCanvas } from "qrcode.react";
import "./QRCodeGenerator.css";
import { getImage } from "../../utils/getImage";

// Get images dynamically
const images = [
  getImage("/whatsapp-pricing/1.svg"),
  getImage("/whatsapp-pricing/2.svg"),
  getImage("/whatsapp-pricing/3.svg"),
  getImage("/whatsapp-pricing/4.svg"),
  getImage("/whatsapp-pricing/5.svg"),
  getImage("/whatsapp-pricing/6.svg"),
  getImage("/whatsapp-pricing/7.svg"),
  getImage("/whatsapp-pricing/8.svg"),
  getImage("/whatsapp-pricing/9.svg"),
  getImage("/whatsapp-pricing/10.svg"),
];

const Frameimages = [
  getImage("/icons/first-icon.svg"),
  getImage("/icons/second.svg"),
  getImage("/icons/third.svg"),
  getImage("/icons/fourth.svg"),
  getImage("/icons/five.svg"),
  getImage("/icons/six.svg"),
  getImage("/icons/seven.svg"),
  getImage("/icons/eight.svg"),
  getImage("/icons/nine.svg"),
  getImage("/icons/ten.svg"),
  getImage("/icons/eleven.svg"),
  getImage("/icons/twelve.svg"),
  getImage("/icons/thirteen.svg"),
  getImage("/icons/fouteen.svg"),
  getImage("/icons/fifteen.svg"),
  getImage("/icons/sixteen.svg"),
  getImage("/icons/seventeen.svg"),
];


function QRCodeGenerator() {
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [welcomeMessage, setWelcomeMessage] = useState(""); 
  const [frameStyle, setFrameStyle] = useState("none");
  const [shape, setShape] = useState("square");
  const [logo, setLogo] = useState("");
  const [level, setLevel] = useState("L");
  const [qrValue, setQrValue] = useState("");
  const [isCustomized, setIsCustomized] = useState(false);
  const [activeTab, setActiveTab] = useState("frame"); // Default tab: Frame
  const [selectedOption, setSelectedOption] = useState("L"); // Default to "L"
  const [selectedLogos, setSelectedLogos] = useState(images); // ✅ Default images set
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [isNestedPopupOpen, setIsNestedPopupOpen] = useState(false);

const handleNestedPopupToggle = () => {
  setIsNestedPopupOpen(!isNestedPopupOpen);
};

const handleSubmit = (event) => {
  event.preventDefault();
  console.log("Form submitted!");
};

  
  // यह function selectedLogo को update करेगा
  const handleLogoSelect = (logo) => {
    const img = new Image();
    img.crossOrigin = "anonymous"; // Cross-origin issue fix
    img.src = logo;
    img.onload = () => setSelectedLogo(logo);
  };
  
  // File Upload & QR Logo Update
  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const logoUrl = reader.result;
        setSelectedLogos((prevLogos) => [...prevLogos, logoUrl]);
        setSelectedLogo(logoUrl); // Update QR logo in real-time
      };
      reader.readAsDataURL(file);
    }
    setIsCustomized(true);
  };
  const qrRef = useRef(null);
  const downloadQRCode = () => {
    const canvas = qrRef.current?.querySelector("canvas");
  
    if (!canvas) {
      alert("QR Code not found!");
      return;
    }
  
    const newCanvas = document.createElement("canvas");
    newCanvas.width = canvas.width;
    newCanvas.height = canvas.height;
    const ctx = newCanvas.getContext("2d");
  
    if (ctx) {
      // 🛠️ Cross-Origin Issue Fix
      ctx.drawImage(canvas, 0, 0);
      setTimeout(() => { // ✅ Wait for Image to Load
        const pngUrl = newCanvas.toDataURL("image/png", 1.0);
        const downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = "QR_Code.png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }, 200);
    }  
    // Ensuring Cross-Origin Handling
    const image = new Image();
    image.crossOrigin = "anonymous";
    image.src = canvas.toDataURL("image/png", 1.0);
  
    image.onload = () => {
      const newCanvas = document.createElement("canvas");
      newCanvas.width = canvas.width;
      newCanvas.height = canvas.height;
      const ctx = newCanvas.getContext("2d");
  
      if (ctx) {
        ctx.drawImage(image, 0, 0);
        const pngUrl = newCanvas.toDataURL("image/png", 1.0);
        const downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = "QR_Code.png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    };
  };
  

  useEffect(() => {
    if (whatsappNumber) {
      const url = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
        welcomeMessage || ""
      )}`;
      setQrValue(url);
    } else {
      setQrValue("");
    }
  }, [whatsappNumber, welcomeMessage]);

  return (
    <section>
      <div className="container qr-code mt-5">
        <div className="row qr-code-inner">
          {/* Left Section */}
          <div className="col-lg-9 col-md-9 col-sm-12">
            <h6 className="text-start mb-4 mt-1">1. Complete the Content</h6>
            <div style={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
              <div style={{ flex: 1 }}>
                <label>Type your WhatsApp Number</label>
                <input
                  type="text"
                  className="form-control"
                  value={whatsappNumber}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (/^\d{0,10}$/.test(inputValue)) {
                      setWhatsappNumber(inputValue);
                    }
                  }}
                  placeholder="+91 Your WhatsApp number here"
                />
              </div>
              <div style={{ flex: 1 }}>
                <label>Welcome Message (Optional)</label>
                <input
                  type="text"
                  className="form-control"
                  value={welcomeMessage}
                  onChange={(e) => setWelcomeMessage(e.target.value)}
                  placeholder="e.g., Hello, I want to know more about your service."
                />
              </div>
            </div>
            <hr></hr>

            <h6 className="text-start my-4">2. Design Your QR</h6>
            <div className="qr-tabs text-start">  
              {/* Tabs Navigation */}
              <div className="tabs">
                <button
                  className={activeTab === "frame" ? "active-tab" : ""}
                  onClick={() => setActiveTab("frame")}
                >
                  Frame
                </button>
                <button
                  className={activeTab === "shape" ? "active-tab" : ""}
                  onClick={() => setActiveTab("shape")}
                >
                  Shape
                </button>
                <button
                  className={activeTab === "logo" ? "active-tab" : ""}
                  onClick={() => setActiveTab("logo")}
                >
                  Logo
                </button>
                <button
                  className={activeTab === "level" ? "active-tab" : ""}
                  onClick={() => setActiveTab("level")}
                >
                  Level
                </button>
              </div>
            </div>

            {/* Tabs Content */}
            {activeTab === "frame" && (
              <div className="tab-content ">
                <label>Select a Frame</label>
                <div className="logo-selection-container border-style">
                  {Frameimages.map((image, index) => (
                    <div key={index} className="logo-preview" onClick={() => {
                      setSelectedLogo(image);
                      setIsCustomized(true);
                    }}>
                      <img src={image} alt={`Frame ${index + 1}`} className="logo-img" />
                    </div>
                  ))}
                </div>
              </div>
            )}
            {activeTab === "shape" && (
              <div className="tab-content">
                <label>Shape</label>
                <select
                  className="form-control"
                  value={shape}
                  onChange={(e) => {
                    setShape(e.target.value);
                    setIsCustomized(true);
                  }}
                >
                  <option value="square">Square</option>
                  <option value="circle">Circle</option>
                </select>
              </div>
            )}
            {activeTab === "logo" && (
              <div className="tab-content">
                <label> Select a Logo</label>

                {/* Scrollable Logo Selection Row */}

                {/* Logo Selection */}
                <div className="logo-selection-container">
                  {selectedLogos.length > 0 ? (
                    selectedLogos.map((logo, index) => (
                      <div key={index} className="logo-preview" onClick={() => handleLogoSelect(logo)}>
                        <img src={logo} alt={`Logo ${index + 1}`} className="logo-img" style={{ cursor: "pointer" }} />
                      </div>
                    ))
                  ) : (
                    <p>No logo selected</p>
                  )}
                </div>
                {/* File Input for Logo Upload */}
                <input type="file" className="form-control pt-3" onChange={handleFileSelect} />
              </div>
            )}
            {activeTab === "level" && (
              <div className="label">
                <label>Select a level</label>
                <div className="tab-content">

                  <div className="qr-row">
                    {[
                      { value: "Level L", percentage: "25%" },
                      { value: "Level M", percentage: "50%" },
                      { value: "Level Q", percentage: "75%" },
                      { value: "Level H", percentage: "100%" },
                    ].map((option) => (
                      <div
                        key={option.value}
                        className={`qr-section ${selectedOption === option.value ? "selected" : ""}`}
                        onClick={() => setSelectedOption(option.value)} // Handle click to set selected option
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" fill="none"><path fill="currentColor" fill-rule="evenodd" d="M0 31.807V0h31.808v31.807H0Zm7.484-7.485h16.84V7.483H7.483v16.839Z" clip-rule="evenodd"></path><path fill="currentColor" d="M12.723 12.721h7.518v7.518h-7.518z"></path><path fill="currentColor" fill-rule="evenodd" d="M64.192 31.807V0H96v31.807H64.192Zm7.485-7.485h16.839V7.483h-16.84v16.839Z" clip-rule="evenodd"></path><path fill="currentColor" d="M75.758 12.721h7.518v7.518h-7.518z"></path><path fill="currentColor" fill-rule="evenodd" d="M0 95.998V64.19h31.808v31.807H0Zm7.484-7.484h16.84V71.675H7.483v16.839Z" clip-rule="evenodd"></path><path fill="currentColor" d="M12.723 75.758h7.518v7.518h-7.518zM7.519 36.434h7.518v7.518H7.519zM22.064 52.049V44.53h-7.027v7.518h6.852v7.517h7.027V52.05h-6.852ZM53.206 13.879h7.518v7.518h-7.518zM38.169 7.518h7.518v13.879h-7.518zM45.687 0h7.518v7.518h-7.518zM45.687 22.554v7.518H38.17v7.518h15.036V22.554h-7.518ZM53.205 66.99v-7.423h-7.518V44.53H38.17v15.226h7.518v7.423h7.518v7.422h15.036V66.99H53.205ZM53.206 36.434h7.518V51.47h-7.518z"></path><path fill="currentColor" fill-rule="evenodd" d="M88.694 38.168v7.013H96v14.384H75.545v-7.012H68.24V45.36h7.123v-7.192h13.331ZM75.545 52.372h13.149V45.36H75.545v7.012Z" clip-rule="evenodd"></path><path fill="currentColor" d="M88.481 67.083h7.518v15.036h-7.518zM60.723 52.047h7.518v7.518h-7.518zM68.24 74.602h7.518v7.518H68.24zM75.758 65.925h7.518v7.518h-7.518z"></path><path fill="currentColor" d="M74.602 82.117v13.88h7.71v-6.67h6.17v-7.21h-13.88ZM38.169 67.083h7.518v13.879h-7.518zM60.072 88.482v-7.518H45.687V96h7.193v-7.518h7.012V96h7.193v-7.518h-7.013ZM22.555 36.434h15.036v7.518H22.555zM.001 44.531h7.518v15.036H.001z"></path></svg>
                        {/* Display value and percentage below the SVG */}
                        <div className="qr-info d-flex ">
                          <span className="qr-value me-auto my-auto">{option.value}</span>
                          <span className="qr-percentage ms-auto">{option.percentage}</span>
                        </div>

                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Right Section */}
          <div className="col-lg-3 col-md-3 col-sm-12 my-auto">
          <div ref={qrRef} className="qr-code-container">
      {/* QR Code Display */}
      <QRCodeCanvas
        value={qrValue || "https://wa.me/"}
        size={250} // Slightly increased for better clarity
        bgColor="#ffffff"
        fgColor="#000000"
        crossOrigin="anonymous"
        level={level || "H"} // Default to highest error correction level
        style={{
          border: frameStyle === "rounded" ? "10px solid #0A9F8D" :
            frameStyle === "circle" ? "10px solid #0A9F8D" : "none",
          borderRadius: shape === "circle" ? "50%" : "0",
          padding: "0px",
          display: "inline-block"
        }}
        includeMargin
        imageSettings={selectedLogo ? {
          src: selectedLogo,
          height: 40,
          width: 40,
          excavate: true,
          crossOrigin: "anonymous",
        } : undefined}
      />

      {/* Download Button */}
      <button
        className="btn-grad"
        onClick={downloadQRCode}
        disabled={!qrValue}
      >
        Download QR
      </button>
          </div>
          </div>
        </div>
      </div>
          {/* Nested Popup */}
          {isNestedPopupOpen ? (
            <div className="popup-overlay d-flex justify-content-center text-center align-items-center">
              <div className="popup-content bg-white rounded-5 p-md-5 p-3 w-100" style={{ maxWidth: "1000px", }}>
                <div className="d-flex justify-content-between align-items-center text-center">
                  <h5 className="h5 mb-3 mx-auto">Almost There! Get Your QR Code</h5>
                  <button
                    className="btn-close position-absolute top-0 end-0 m-2"
                    onClick={handleNestedPopupToggle}
                    aria-label="Close"
                  ></button>
                </div>
                <div className="row g-4">
                  {/* Left Side - Form */}
                  <div className="col-lg-12 col-md-12">
                    <div className="p-3 shadow-sm rounded bg-white">
                      <form id="contactForm" onSubmit={handleSubmit}>
                        <div className="form-group mb-3 text-center">
                          <input type="text" id="name" className="form-control" placeholder="Enter your name" required />
                        </div>
                        <div className="form-group mb-3">
                          <input type="email" id="email" className="form-control" placeholder="Enter your email" required />
                        </div>
                        <div className="form-group mb-3">
                          <input
                            type="text"
                            id="whatsapp"
                            className="form-control"
                            placeholder="Enter your WhatsApp number"
                            required
                            inputMode="numeric"
                            pattern="[0-9]*"
                            onInput={(e) => e.target.value = e.target.value.replace(/\D/g, '')}
                          />
                        </div>
                        <div className="form-group mb-4">
                          <input type="text" id="company" className="form-control" placeholder="Enter your company name" required />
                        </div>
                        <div className="form-actions d-flex justify-content-center">
                          <button type="submit" className="btn text-white linear_gradiant rounded-pill px-4">
                            Submit & Download QR
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  {/* Right Side - Script Code */}
              
                </div>
              </div>
            </div>
          ) : null}
      
    </section>
  );
}

export default QRCodeGenerator;
