import React from "react";
import "./terms-conditions.css"; // Optional: Add custom CSS file for styling
import ScrollToTopButton from "../components/common/ScrollToTopButton";
import WhatsAppWidget from "../components/common/WhatsAppWidget";
import { Helmet } from 'react-helmet';
const TermsConditions = () => {
  return (
    <>
    <Helmet>
      <title>Terms and Conditions - Anantya.ai</title>
      <meta name="robots" content="noindex,nofollow" /> 
      <meta
        name="description"
        content="Navigate the Guidelines for Using Anantya.ai Services Effectively. Explore Our Service Terms and Conditions for a Clear Understanding of Expectations."
      />
      <meta property="og:title" content="Terms and Conditions - Anantya.ai" />
      <meta property="og:type" content="Article" />
      <meta
        property="og:description"
        content="Navigate the Guidelines for Using Anantya.ai Services Effectively. Explore Our Service Terms and Conditions for a Clear Understanding of Expectations."
      />
      <meta property="og:url" content="https://anantya.ai/terms-and-condition" />
      <meta property="og:site_name" content="anantya.ai" />
      <meta property="og:image" content="" />
      <link rel="canonical" href="https://anantya.ai/terms-and-condition" />
      <meta name="author" content="Anantya.ai" />

      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Home",
              "item": "https://anantya.ai/"
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": "Terms and Condition",
              "item": "https://anantya.ai/terms-and-condition"
            }
          ]
        })}
      </script>
    </Helmet>
    <div className="terms-conditions-page pt-5">
      <div className="container pt-md-0 pt-5">
       <main className="terms-conditions-content my-5 py-3  bg-white  px-5">
      <header className="terms-header">
        <h1 className="display-4">Terms and Conditions</h1>
        <p>Last updated August 17, 2023</p>
      </header>
      <h2>AGREEMENT TO OUR LEGAL TERMS</h2>
      <p>
        We are Anantya.ai ('Company', 'we', 'us', or 'our') , a company registered in the United Arab Emirates, Binary Tower-606, Parcel ID - 346/499, Business Bay, Dubai, United Arab Emirates, Business Bay.
      </p>
      <p>
        We operate the website anantya.ai (the 'Site') , as well as any other related products and services that refer or link to these legal terms (the 'Legal Terms') (collectively, the 'Services').
      </p>
      <p>
        You can contact us by phone at +971 56 548 0273, email at info@anantya.ai, or by mail to Binary Tower-606, Parcel ID - 346/499, Business Bay, Dubai, United Arab Emirates, Business Bay, Dubai, United Arab Emirates.
      </p>
      <p>
        These Legal Terms constitute a legally binding agreement made between you, whether personally or on behalf of an entity ('you'), and Anantya.ai, concerning your access to and use of the Services. You agree that by accessing the Services, you have read, understood, and agreed to be bound by all of these Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.
      </p>
      <p>
        We will provide you with prior notice of any scheduled changes to the Services you are using. The modified Legal Terms will become effective upon posting or notifying you by __________, as stated in the email message. By continuing to use the Services after the effective date of any changes, you agree to be bound by the modified terms.
      </p>
      <p>
        The Services are intended for users who are at least 18 years old. Persons under the age of 18 are not permitted to use or register for the Services.
      </p>
      <p>
        We recommend that you print a copy of these Legal Terms for your records.
      </p>

      <h2>TABLE OF CONTENTS</h2>
      <ol>
          <li><a href="#services">Our Services</a></li>
          <li><a href="#intellectual-property">Intellectual Property Rights</a></li>
          <li><a href="#user-representations">User Representations</a></li>
          <li><a href="#user-registration">User Registration</a></li>
          <li><a href="#prohibited-activities">Prohibited Activities</a></li>
          <li><a href="#user-generated-contributions">User Generated Contributions</a></li>
          <li><a href="#contribution-license">Contribution License</a></li>
          <li><a href="#services-management">Services Management</a></li>
          <li><a href="#term-termination">Term and Termination</a></li>
          <li><a href="#modifications-interruptions">Modifications and Interruptions</a></li>
          <li><a href="#governing-law">Governing Law</a></li>
          <li><a href="#dispute-resolution">Dispute Resolution</a></li>
          <li><a href="#corrections">Corrections</a></li>
          <li><a href="#disclaimer">Disclaimer</a></li>
          <li><a href="#limitations-liability">Limitations of Liability</a></li>
          <li><a href="#indemnification">Indemnification</a></li>
          <li><a href="#user-data">User Data</a></li>
          <li><a href="#electronic-communications">Electronic Communications, Transactions, and Signatures</a></li>
          <li><a href="#miscellaneous">Miscellaneous</a></li>
          <li><a href="#contact-us">Contact Us</a></li>
        </ol>

      <h2>1. OUR SERVICES</h2>
      <p>
        The information provided when using the Services is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose to access the Services from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.
      </p>

      <h2>2. INTELLECTUAL PROPERTY RIGHTS</h2>
      <p>
        <strong>Our intellectual property</strong>
        <br />
        We are the owner or the licensee of all intellectual property rights in our Services, including all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics in the Services (collectively, the 'Content'), as well as the trademarks, service marks, and logos contained therein (the 'Marks').
      </p>
      <p>
        Our Content and Marks are protected by copyright and trademark laws (and various other intellectual property rights and unfair competition laws) and treaties in the United States and around the world.
      </p>
      <p>
        The Content and Marks are provided in or through the Services 'AS IS' for your personal, non-commercial use or internal business purpose only.
      </p>
      <p>
        <strong>Your use of our Services</strong>
        <br />
        Subject to your compliance with these Legal Terms, including the 'PROHIBITED ACTIVITIES' section below, we grant you a non-exclusive, non-transferable, revocable licence to:
        <ul>
          <li>access the Services; and</li>
          <li>download or print a copy of any portion of the Content to which you have properly gained access.</li>
        </ul>
        solely for your personal, non-commercial use or internal business purpose.
      </p>
      <p>
        Except as set out in this section or elsewhere in our Legal Terms, no part of the Services and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission.
      </p>
      <p>
        If you wish to make any use of the Services, Content, or Marks other than as set out in this section or elsewhere in our Legal Terms, please address your request to: info@anantya.ai. If we ever grant you the permission to post, reproduce, or publicly display any part of our Services or Content, you must identify us as the owners or licensors of the Services, Content, or Marks and ensure that any copyright or proprietary notice appears or is visible on posting, reproducing, or displaying our Content.
      </p>
      <p>
        We reserve all rights not expressly granted to you in and to the Services, Content, and Marks.
      </p>
      <p>
        Any breach of these Intellectual Property Rights will constitute a material breach of our Legal Terms and your right to use our Services will terminate immediately.
      </p>

      <h2>3. USER REPRESENTATIONS</h2>
      <p>
        By using the Services, you represent and warrant that:
        <ul>
          <li>You have the legal capacity and you agree to comply with these Legal Terms.</li>
          <li>You are not under the age of 18.</li>
          <li>You will not access the Services through automated or non-human means, whether through a bot, script, or otherwise.</li>
          <li>You will not use the Services for any illegal or unauthorized purpose.</li>
          <li>Your use of the Services will not violate any applicable law or regulation.</li>
        </ul>
      </p>

      <h2>4. USER REGISTRATION</h2>
      <p>
        You may be required to register for an account to access certain features of the Services. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete. We may suspend or terminate your account if you provide any information that is false, inaccurate, not current, or incomplete, or if we suspect that you have engaged in any prohibited activities.
      </p>

      <h2>5. PROHIBITED ACTIVITIES</h2>
      <p>
        You may not access or use the Services for any purpose other than that for which we make the Services available. The Services may not be used in connection with any commercial endeavors except those that are specifically endorsed or approved by us.
      </p>
      <p>
        As a user of the Services, you agree not to:
        <ul>
          <li>Systematically retrieve data or other content from the Services to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission from us.</li>
          <li>Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords.</li>
          <li>Engage in unauthorized framing of or linking to the Services.</li>
          <li>Disrupt, interfere with, or create an undue burden on the Services or the networks or services connected to the Services.</li>
          <li>Use the Services to cheat, hack, or otherwise bypass or defeat the security of the Services.</li>
        </ul>
      </p>

      <h2>6. USER GENERATED CONTRIBUTIONS</h2>
      <p>
        The Services may invite you to chat, contribute to, or participate in blogs, message boards, online forums, and other functionality, and may provide you with the opportunity to create, submit, post, display, transmit, perform, publish, distribute, or broadcast content and materials to us or through the Services, including but not limited to text, writings, video, audio, photographs, graphics, comments, suggestions, or personal information or other material (collectively, 'Contributions').
      </p>
      <p>
        Contributions may be viewable by other users of the Services and through third-party websites. As such, any Contributions you transmit may be treated in accordance with our Privacy Policy.
      </p>
      <p>
        When you create or make available any Contributions, you thereby represent and warrant that:
        <ul>
          <li>You are the creator and owner of the Contributions, or you have the necessary licenses, rights, consents, and permissions to authorize us to use your Contributions as specified in these Legal Terms.</li>
          <li>Your Contributions do not violate the privacy or intellectual property rights of any third party.</li>
          <li>Your Contributions are not false, inaccurate, or misleading.</li>
        </ul>
      </p>

      <h2>7. CONTRIBUTION LICENSE</h2>
      <p>
        By submitting your Contributions, you grant us a worldwide, perpetual, irrevocable, non-exclusive, royalty-free, fully paid-up, transferable license, with the right to sublicense, to use, copy, reproduce, distribute, prepare derivative works of, display, and perform your Contributions in connection with the Services and our (and our successor’s) business, including without limitation for promoting and redistributing part or all of the Services in any media formats and through any media channels.
      </p>

      <h2>8. SERVICES MANAGEMENT</h2>
      <p>
        We reserve the right, but not the obligation, to:
        <ul>
          <li>Monitor the Services for violations of these Legal Terms.</li>
          <li>Take appropriate legal action against anyone who, in our sole discretion, violates the law or these Legal Terms, including without limitation reporting such user to law enforcement authorities.</li>
          <li>Manage the Services in a manner designed to protect our rights and property and to facilitate the proper functioning of the Services.</li>
        </ul>
      </p>

      <h2>9. TERM AND TERMINATION</h2>
      <p>
        These Legal Terms shall remain in full force and effect while you use the Services. We may terminate or suspend your account and access to the Services at our sole discretion, without notice, for any reason, including but not limited to a breach of these Legal Terms.
      </p>

      <h2>10. MODIFICATIONS AND INTERRUPTIONS</h2>
      <p>
        We reserve the right to change, modify, or remove the contents of the Services at any time or for any reason at our sole discretion without notice. However, we have no obligation to update any information in our Services. We also reserve the right to modify or discontinue all or part of the Services without notice at any time.
      </p>
      <p>
        We cannot guarantee that the Services will be available at all times. We may experience hardware, software, or other problems or need to perform maintenance related to the Services, resulting in interruptions, delays, or errors. We have no obligation to provide support for the Services.
      </p>

      <h2>11. GOVERNING LAW</h2>
      <p>
        These Legal Terms and your use of the Services are governed by and construed in accordance with the laws of the United Arab Emirates.
      </p>

      <h2>12. DISPUTE RESOLUTION</h2>
      <p>
        Any legal suit, action, or proceeding arising out of or related to these Legal Terms or the Services shall be instituted exclusively in the courts of the United Arab Emirates.
      </p>

      <h2>13. CORRECTIONS</h2>
      <p>
        There may be information on the Services that contains typographical errors, inaccuracies, or omissions that may relate to the Services, including descriptions, pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update the information on the Services at any time, without prior notice.
      </p>

      <h2>14. DISCLAIMER</h2>
      <p>
        The Services are provided on an 'AS-IS' and 'AS-AVAILABLE' basis. We make no representations or warranties of any kind, express or implied, as to the operation of the Services or the information, content, materials, or products included on the Services. You expressly agree that your use of the Services is at your sole risk.
      </p>

      <h2>15. LIMITATIONS OF LIABILITY</h2>
      <p>
        To the fullest extent permitted by applicable law, in no event shall we, our affiliates, or our respective officers, directors, employees, or agents be liable to you or any third party for any indirect, incidental, special, consequential, or punitive damages, including lost profits, whether in an action in contract, tort, or otherwise, arising from your use of the Services.
      </p>

      <h2>16. INDEMNIFICATION</h2>
      <p>
        You agree to indemnify, defend, and hold harmless us, our affiliates, and our respective officers, directors, employees, and agents from and against any and all claims, liabilities, damages, losses, and expenses, including reasonable attorneys' fees, arising from or in any way related to your use of the Services, your Contributions, or your violation of these Legal Terms.
      </p>

      <h2>17. USER DATA</h2>
      <p>
        We will maintain certain data that you transmit to the Services for the purpose of managing the performance of the Services, as well as data relating to your use of the Services. Although we perform regular backups of data, you are solely responsible for all data that you transmit or that relates to any activity you have undertaken using the Services.
      </p>
      <p>
        You agree that we shall have no liability to you for any loss or corruption of any such data, and you hereby waive any right of action against us arising from any such loss or corruption of such data.
      </p>

      <h2>18. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</h2>
      <p>
        Visiting the Services, sending us emails, and completing online forms constitute electronic communications. You consent to receive electronic communications and you agree that all agreements, notices, disclosures, and other communications we provide to you electronically, via email and on the Services, satisfy any legal requirement that such communications be in writing.
      </p>

      <h2>19. MISCELLANEOUS</h2>
      <p>
        These Legal Terms and any policies or operating rules posted by us on the Services or in respect to the Services constitute the entire agreement and understanding between you and us. Our failure to exercise or enforce any right or provision of these Legal Terms shall not operate as a waiver of such right or provision. These Legal Terms operate to the fullest extent permissible by law. We may assign any or all of our rights and obligations to others at any time. We shall not be responsible or liable for any loss, damage, delay, or failure to act caused by any cause beyond our reasonable control.
      </p>
      <p>
        If any provision or part of a provision of these Legal Terms is determined to be unlawful, void, or unenforceable, that provision or part of the provision is deemed severable from these Legal Terms and does not affect the validity and enforceability of any remaining provisions.
      </p>

      <h2>20. CONTACT US</h2>
      <p>
        In order to resolve a complaint regarding the Services or to receive further information regarding use of the Services, please contact us at:
        <br />
        Email: info@anantya.ai
        <br />
        Phone: +971 56 548 0273
        <br />
        Address: Binary Tower-606, Parcel ID - 346/499, Business Bay, Dubai, United Arab Emirates, Business Bay.
      </p>
       
    
       </main>
      </div>
      <ScrollToTopButton />
      <WhatsAppWidget />
    </div>
    </>
  );
};

export default TermsConditions;
