import React, { } from "react";
import "./HeroSection2.css";
import { getImage } from "../../utils/getImage"; // Utility function for dynamic image fetching
import TabsSection2 from "./TabsSection2";
import { motion } from "framer-motion";
const meta = getImage("/whatsapp-pricing/Meta.png");
const homepageframe = getImage("homepagegraphices.png");
const curveImage = getImage("/whatsapp-pricing/curve.png");
const HeroSection2 = () => {

  const scrollToForm = () => {
    const formElement = document.getElementById("myStickyForm");
    if (formElement) {
      formElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  return (
    <>
      <div className="hero-section2 mt-lg-0 mt-md-0 mt-5">
        <div className="hero-banner">
          <div className="hero-top mt-lg-5 mt-md-5 mt-4">
            <span className="badge">
              Powered by Official WhatsApp Business API &nbsp;
              <svg
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
              >
                <path
                  d="m.76 21.24 1.412-5.12A10.324 10.324 0 0 1 .76 10.93C.76 5.35 5.35.76 10.964.76 16.58.76 21.24 5.35 21.24 10.93c0 5.578-4.661 10.31-10.276 10.31-1.765 0-3.46-.565-4.978-1.413L.76 21.24Z"
                  fill="#EDEDED"
                ></path>
                <path
                  d="m6.268 17.991.318.177c1.307.812 2.825 1.306 4.414 1.306 4.626 0 8.474-3.848 8.474-8.545 0-4.696-3.848-8.404-8.51-8.404-4.66 0-8.439 3.743-8.439 8.404 0 1.624.46 3.213 1.307 4.555l.212.318-.812 2.966 3.036-.777Z"
                  fill="#25D366"
                ></path>
                <path
                  d="m8.245 6.198-.671-.036a.802.802 0 0 0-.565.212c-.318.283-.848.812-.989 1.519-.247 1.059.141 2.33 1.06 3.601.918 1.271 2.683 3.32 5.79 4.202.989.283 1.766.106 2.402-.282.494-.318.812-.812.918-1.342l.105-.494a.355.355 0 0 0-.176-.389l-2.225-1.024a.337.337 0 0 0-.423.106l-.883 1.13a.275.275 0 0 1-.283.07c-.6-.211-2.613-1.059-3.707-3.177-.036-.106-.036-.212.035-.283l.848-.953c.07-.106.105-.247.07-.353L8.527 6.41a.308.308 0 0 0-.282-.212Z"
                  fill="#FEFEFE"
                ></path>
              </svg>
            </span>
            <a href="https://www.facebook.com/business/partner-directory/search?solution_type=messaging&platforms=whatsapp&id=4336810659772608&section=overview" className="meta-link" style={{ textDecoration: "none" }} target="_blank" rel="noopener noreferrer">
              <span>
                <span>
                  <img
                    src={meta}
                    className="img-fluid meta_logo text-white"
                    alt="Meta Logo"
                  />
                </span>
                <u>Meta <small> Tech Partner </small></u>
              </span>
            </a>

          </div>
    
<h1 className="hero-title">
  Engage Smarter with Anantya's <br />
  <motion.span
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 1, delayChildren: 0.2, staggerChildren: 0.1 }}
  >
    {`WhatsApp Business API`.split("").map((char, index) => (
      <motion.span key={index} initial={{ opacity: 0 }}  animate={{ opacity: 1 }} transition={{ delay: index * 0.1 }}>
        {char}
      </motion.span>
    ))}
  </motion.span>
</h1>

       {/* <h1 className="hero-title">
            Engage Smarter with Anantya's  <br /> WhatsApp Business API
          </h1> */}
          <p className="hero-subtitle">
            Your success is our priority. With tools designed for today’s fast-paced world, we empower businesses to connect better, faster, and smarter.
          </p>
          <div className="hero-buttons">
            <a type="button" href="/contact" className="btn-book" target="_blank" rel="noopener noreferrer"> Get Started →</a>

            <button className="btn-get" onClick={scrollToForm}> Book a Demo →</button>

          </div>
          <p className="hero-note pt-3">
            (Get started for free + Access all the essentials with our free plan)
          </p>
          <div className="hero-icon-network d-none ">
            <img src={homepageframe} className="img-fluid" alt="homepage-image" />
          </div>

        </div>

        <TabsSection2 /> {/* Include your Hero Section */}
      </div>
      <div>
        <img
          src={curveImage}
          alt="Curve Background" className="curve_image"
          style={{ width: "100%", height: "auto", display: "block", }}
        />
      </div>

    </>
  );
};

export default HeroSection2;
