import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import ScrollToTopButton from "../components/common/ScrollToTopButton";
import WhatsAppWidget from "../components/common/WhatsAppWidget";
import "./DigitalServices.css"; // Import your CSS file if needed
import { getImage } from '../utils/getImage'; // Adjust the path to your utility function
// Fetching images dynamically using the getImage function
const seoImage1 = getImage('SEO-Consultancy.png'); // Adjust path as needed
const seoImage2 = getImage('Competitor-Analysis.png');
const seoImage3 = getImage('Social-Media-Marketing.png');


const DigitalServices = () => {
  return (
    <div>
      <Container className="py-5">
        <h2 className="text-center mb-4">Our Digital Services</h2>
        <Row>
          <Col md={6} lg={4}>
            <Card className="mb-4 shadow-sm h-100">
              <Card.Body>
                <Card.Title>Web Development</Card.Title>
                <Card.Text>
                  We create modern, responsive, and user-friendly websites to enhance your online presence.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} lg={4}>
            <Card className="mb-4 shadow-sm h-100">
              <Card.Body>
                <Card.Title>Static Websites</Card.Title>
                <Card.Text>
                  Fast and lightweight websites, perfect for portfolios and small businesses.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} lg={4}>
            <Card className="mb-4 shadow-sm h-100">
              <Card.Body>
                <Card.Title>Dynamic Websites</Card.Title>
                <Card.Text>
                  Feature-rich and interactive websites powered by the latest technologies.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      
      <div className="solutions container text-center">
        <div className=" row row-cols-1 row-cols-md-3 g-4 pb-5 px-md-0 px-3 ">
          <div class="col text-center h-100">
            <img src={seoImage1} className="img-fluid" alt="" />
             <div class="info">
                <h5 class="card-title">
                  Profound Expertise
                </h5>

              </div>
         </div>
          <div class="col text-center h-100">
          <img src={seoImage2} className="img-fluid" alt="" />
             <div class="info">
                <h5 class="card-title">
                Customized Solutions
                </h5>
              </div>
           </div>
          <div class="col text-center h-100">
          <img src={seoImage3} className="img-fluid" alt="" />
              <div class="info">
                <h5 class="card-title">
                Support and Maintenance
                </h5>

              </div>
      
          </div>
        </div>
      </div>
      <ScrollToTopButton />
      <WhatsAppWidget />
    </div>

  );
};

export default DigitalServices;
