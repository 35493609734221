import React, { useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './LeadForm.css'; // Optional: Add custom CSS file for styling
const LeadForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    leadSource: '',
    leadOwner: '',
    website: '',
    designation: '',
    phone: '',
    remarks: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newLead = { ...formData, date: new Date().toLocaleDateString() };

    const storedLeads = JSON.parse(localStorage.getItem('leads')) || [];
    storedLeads.push(newLead);
    localStorage.setItem('leads', JSON.stringify(storedLeads));

    setFormData({
      name: '',
      email: '',
      company: '',
      leadSource: '',
      leadOwner: '',
      website: '',
      designation: '',
      phone: '',
      remarks: ''
    });

    alert('Lead Submitted Successfully!');
  };

  return (
    <div className="container py-5">
      <h3 className="pb-3 mt-4">Lead Entry Form</h3>
      <form onSubmit={handleSubmit}>

        {/* Mandatory Details */}
        <fieldset className="border p-5 mb-3 shadow rounded-4">
          <legend className="h5">Mandatory Details</legend>
          <div className="mb-3">
            <label className="form-label">Contact Person Name:</label>
            <input type="text" name="name" className="form-control" value={formData.name} onChange={handleChange} required />
          </div>
          <div className="mb-3">
            <label className="form-label">Contact Person Email:</label>
            <input type="email" name="email" className="form-control" value={formData.email} onChange={handleChange} required />
          </div>
          <div className="mb-3">
            <label className="form-label">Company Name:</label>
            <input type="text" name="company" className="form-control" value={formData.company} onChange={handleChange} required />
          </div>
          <div className="mb-3">
            <label className="form-label">Lead Source:</label>
            <select name="leadSource" className="form-control" value={formData.leadSource} onChange={handleChange} required>
          
              <option value="Website">Website</option>
              <option value="Referral">Referral</option>
              <option value="Cold Call">Cold Call</option>
              <option value="LinkedIn">LinkedIn</option>
              <option value="Events">Events</option>
            </select>
          </div>
          <div className="mb-3">
            <label className="form-label">Lead Owner:</label>
            <input
              type="text"
              name="leadOwner"
              className="form-control"
              value={formData.leadOwner}
              onChange={handleChange}
              placeholder="Enter Lead Owner Name"
              required
            />
          </div>
    

        {/* Additional Details */}
    
          <legend className="h5">Additional Details</legend>
          <div className="mb-3">
            <label className="form-label">Company Website:</label>
            <input type="text" name="website" className="form-control" value={formData.website} onChange={handleChange} />
          </div>
          <div className="mb-3">
            <label className="form-label">Contact Designation:</label>
            <input
              type="text"
              name="designation"
              className="form-control"
              value={formData.designation}
              onChange={handleChange}
              placeholder="Enter Contact Designation"
            />
          </div>

          <div className="mb-3">
          <label className="form-label">Phone Number (with Country Code):</label>
         <PhoneInput
    international
    defaultCountry="IN"  // Set default country to India
    value={formData.phone}
    onChange={(value) => setFormData({ ...formData, phone: value })}
    className="form-control" inputClass="form-control"
  />  
           </div>
          <div className="mb-3">
            <label className="form-label">Remarks:</label>
            <textarea name="remarks" className="form-control" value={formData.remarks} onChange={handleChange}></textarea>
          </div>
          </fieldset>
       
        <button type="submit" className="btn btn-primary mt-3">Submit Lead</button>
      </form>
    </div>
  );
};

export default LeadForm;
