import React, { useRef } from 'react';
import './GenerateWhatsAppWidget.css'; // Assuming you have a CSS file for styling
import FAQ from "../components/common/FAQ"; // Correct relative path
import { Helmet } from 'react-helmet';
import WhatsAppChatButton from '../components/common/WhatsAppChatButton';
import ScrollToTopButton from '../components/common/ScrollToTopButton';
import WhatsAppWidget from '../components/common/WhatsAppWidget';
import LogoCarousel from '../components/common/LogoCarousel';
import { getImage } from '../utils/getImage'; // Adjust the path to your utility function
import YoutubeVideoSlider from "../components/common/YoutubeVideoSlider";
// Fetching images dynamically using the getImage function
const whatsappImage = getImage('whatsapp-widget-generetor.png');
const step1Image = getImage('icons/Technology.png');
const step2Image = getImage('icons/custom.png');
const step3Image = getImage('icons/Shuttle.png');
const checkImage = getImage('icons/Vector.png');

const GenerateWhatsAppWidget = () => {
 // Reference to WhatsAppChatButton
 const chatButtonRef = useRef(null);
 // Scroll function to bring WhatsAppChatButton into view
 const scrollToChatButton = () => {
   if (chatButtonRef.current) {
     chatButtonRef.current.scrollIntoView({ behavior: 'smooth' });
   }
 };

  return (
    <>
     <Helmet>
      <title>WhatsApp Widget Generator | Add a WhatsApp Widget to Your Website</title>
      <meta name="robots" content="index,follow" />
      <meta
        name="description"
        content="Add a WhatsApp widget to your site easily. Improve customer communication and engagement with our fast and simple WhatsApp Widget Generator."
      />
      <link rel="alternate" href="https://anantya.ai/whatsapp-widget" hreflang="en-IN" />
      <link rel="alternate" href="https://anantya.ai/whatsapp-widget" hreflang="en-SA" />
      <link rel="alternate" href="https://anantya.ai/whatsapp-widget" hreflang="en-AE" />
      <meta property="og:title" content="WhatsApp Widget Generator | Add a WhatsApp Widget to Your Website" />
      <meta
        property="og:description"
        content="Add a WhatsApp widget to your site easily. Improve customer communication and engagement with our fast and simple WhatsApp Widget Generator."
      />
      <meta property="og:type" content="article" />
      <meta property="og:locale" content="en_AE" />
      <meta property="og:locale" content="en_SA" />
      <meta property="og:locale" content="en_IN" />
      <meta property="og:url" content="https://anantya.ai/whatsapp-widget-generator" />
      <meta property="og:site_name" content="https://anantya.ai" />
      <meta property="og:image" content="____" />
      <link rel="canonical" href="https://anantya.ai/whatsapp-widget-generator" />
      <meta name="author" content="Anantya.ai" />

      {/* Schema for Organization */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Organization",
          name: "Anantya.ai",
          alternateName: "Conversational Engagement Platform for Businesses | Anantya.ai",
          url: "https://anantya.ai/",
          logo: "https://ik.imagekit.io/cloy701fl/images/logo.webp",
          contactPoint: {
            "@type": "ContactPoint",
            telephone: "971565480273",
            contactType: "sales",
            areaServed: ["SA", "AE", "IN"],
            availableLanguage: "en",
          },
          sameAs: [
            "https://www.facebook.com/anantyaai",
            "https://www.instagram.com/anantya.ai",
            "https://www.youtube.com/@Anantyaai",
            "https://www.pinterest.com/anantyaai",
            "https://www.linkedin.com/company/anantya-ai",
          ],
        })}
      </script>

      {/* Schema for WebPage */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "WebPage",
          name: "WhatsApp Widget Generator | Add a WhatsApp Widget to Your Website",
          description:
            "Add a WhatsApp widget to your site easily. Improve customer communication and engagement with our fast and simple WhatsApp Widget Generator.",
          url: "https://anantya.ai/whatsapp-widget-generator",
          publisher: {
            "@type": "Organization",
            name: "Anantya.ai",
            logo: {
              "@type": "ImageObject",
              url: "https://ik.imagekit.io/cloy701fl/images/logo.webp",
            },
          },
          mainEntityOfPage: {
            "@type": "WebPage",
            url: "https://anantya.ai/whatsapp-widget-generator",
          },
        })}
      </script>

      {/* Schema for BreadcrumbList */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org/",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              name: "Home",
              item: "https://anantya.ai/",
            },
            {
              "@type": "ListItem",
              position: 2,
              name: "WhatsApp Widget Generator | Add a WhatsApp Widget to Your Website",
              item: "https://anantya.ai/whatsapp-widget-generator",
            },
          ],
        })}
      </script>
    </Helmet>

      {/* Hero Section */}
      <section className='gradient_1 mt-0'>
      <div className="container py-5 my-4 ">
        <div className="row">
          <div className="info col-xl-6 col-lg-6 col-md-6 col-sm-12 my-auto text-md-start">
            <h2 className="pt-3 h1 fw-bold">Design your perfect WhatsApp widget today!</h2>
            <p className="py-3">
              Personalize a chat button with your preferred color and call-to-action to seamlessly integrate with your website.
            </p>
            <button
                type="button"
                onClick={scrollToChatButton}
                className="btn me-md-2 text-white linear_gradiant"
              >
                Create Widget
              </button>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <img
              src={whatsappImage}
              className="img-fluid"
              alt="Generate WhatsApp URL"
            />
          </div>
        </div>
      </div>
      </section>

      {/* Trusted By Section */}
      <div className="text-center pt-2">
        <h2 className=" bg-gradient_headings">Trusted by 100+ brands across industries and regions</h2>
        <LogoCarousel />
        <p className="color-666 line_breaker bg-gradients text-center mt-5">
          Generate leads, create help tickets, and chat across channels{' '}
          <a
            href="https://wa.link/hd4mvx"
            className="color-000 fw-bold text-white text-underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            <u>Get Started Now</u>
          </a>
        </p>
      </div>

  
{/* Other components */}
    <div ref={chatButtonRef}>
        <WhatsAppChatButton />
      </div>
          {/* Features Section */}
          <div className="container text-md-start pb-5">
        <h2 className="text-center h4 whatsapp_heading">Here's Why You Need Anantya's Free WhatsApp Widget</h2>
        <p className="text-center pb-2">Enhance User Experience and Drive Conversion</p>
        <div className="row row-cols-1 row-cols-md-3 g-4">
          {/* Step 1 */}
          <div className="col">
            <div className="widget-card h-100 py-md-4">
              <img src={step1Image} className="img-fluid stepimage my-3" alt="Step 1" />
              <h5>Seamless Integration</h5>
              <p>Anantya's free WhatsApp widget easily integrates with your website, allowing you to connect with customers instantly and effortlessly.</p>
            </div>
          </div>

          {/* Step 2 */}
          <div className="col">
            <div className="widget-card h-100 py-md-4">
              <img src={step2Image} className="img-fluid stepimage my-3" alt="Step 2" />
              <h5>Fully Customizable</h5>
              <p>Tailor the chat button’s design, color, and call-to-action to match your brand identity, enhancing user experience and engagement.</p>
            </div>
          </div>

          {/* Step 3 */}
          <div className="col">
            <div className="widget-card h-100 py-md-4">
              <img src={step3Image} className="img-fluid stepimage my-3" alt="Step 3" />
              <h5>Boosts Conversions</h5>
              <p>By making it easier for visitors to contact you directly on WhatsApp, Anantya’s widget helps increase lead generation and drive higher conversions.</p>
            </div>
          </div>
        </div>
      </div>
      {/* WhatsApp Link Generator Section */}
      <section style={{ backgroundColor: "rgba(255, 250, 242, 1)" }}>
         <YoutubeVideoSlider />
      </section>
      {/* Call-to-Action Section */}
      <div className="container my-3">
        <div className="update-cta p-4">
          <div className="in-cta p-2">
            <h5 className="h6 pt-3 mt-2 px-md-5 mx-md-5">
              <b>Discover why</b> WhatsApp, Facebook, and Instagram are unbeatable in social media messaging. Harness their power with one of the fastest-growing WhatsApp Business <br/> solutions!
            </h5>
            <div className="py-2 mb-2">
              <a href="https://wa.link/hd4mvx" className="btn btn-graident me-3 " role="button">
                Free 7 Day Trial
              </a>
              <a href="https://wa.link/hd4mvx" className="btn btn-graident-2 custom-border mt-lg-0 mt-md-0 mt-2" role="button">
                Book a Demo
              </a>
            </div>
          </div>
        </div>
      </div>
    <FAQ />
         {/* Why Choose Anantya Section */}
         <div className="container mt-4 mb-0 py-0 justify-content-between justify-content-end">
        <div className="p-4 pb-0 text-center">
       
          <h5 className="px-md-5 mx-md-5 px-0 heading-gradient">Why Choose Anantya.ai?</h5>
          <div className="row pt-3 justify-content-between text-center">
            <div className="col-12 col-sm-6 col-md-3 d-flex align-items-center mb-3">
              <img src={checkImage} alt="Check" className="feature-tick-icon me-3" />
              <span>Live Agent Assistant</span>
            </div>
            <div className="col-12 col-sm-6 col-md-3 d-flex align-items-center mb-3">
              <img src={checkImage} alt="Check" className="feature-tick-icon me-3" />
              <span>No Extra Hidden Charges</span>
            </div>
            <div className="col-12 col-sm-6 col-md-3 d-flex align-items-center mb-3">
              <img src={checkImage} alt="Check" className="feature-tick-icon me-3" />
              <span>24*7 Customer Support</span>
            </div>
            <div className="col-12 col-sm-6 col-md-3 d-flex align-items-center mb-3">
              <img src={checkImage} alt="Check" className="feature-tick-icon me-3" />
              <span>Drive Sales</span>
            </div>
          </div>
          <div className="my-3">
            <a href="/whatsapp-pricing" className="btn btn-graident me-3 px-md-5 px-4" role="button">
              Try Anantya.ai
            </a>
          </div>
        </div>
      </div>
      {/* Scroll to Top and WhatsApp Widget */}
    
      <ScrollToTopButton />
      <WhatsAppWidget />
    </>
  );
};

export default GenerateWhatsAppWidget;
