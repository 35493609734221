import React, { useState } from "react";
import ScrollToTopButton from "../components/common/ScrollToTopButton";
import WhatsAppWidget from "../components/common/WhatsAppWidget";
import ReCAPTCHA from "react-google-recaptcha";
import { FaCalendarAlt, FaMapPin, FaClock } from "react-icons/fa";
import { getImage } from '../utils/getImage'; // Adjust the path as needed
import './WhatsnxtDelhiEvent.css'; // Optional: Add custom CSS file for styling

// Fetching images dynamically using the getImage function
const EventImage = getImage('/whatnxt-event.png');
const EventImage2 = getImage('/Why-WhatsNXT_.webp');
const EventImage3 = getImage('/whatsAppchatbot.png');
const EventImage4 = getImage('/journeyswith flows.png');
const EventImage5 = getImage('/whatsapp payments.png');
const EventImage6 = getImage('/versatilei ntegrations.png');
const icon1 = getImage('/event-icon-1.png');
const icon2 = getImage('/event-icon-2.png');
const icon3 = getImage('/event-icon-3.png');
const icon4 = getImage('/event-icon-4.png');
const WhatsnxtDelhiEvent = () => {
    const [captchaVerified, setCaptchaVerified] = useState(false);

    // ReCAPTCHA verification function
    const handleRecaptcha = (value) => {
        if (value) {
            setCaptchaVerified(true);
        } else {
            setCaptchaVerified(false);
        }
    };

    return (
        <>
            <section className="nextevent">
                <div className="container py-5 my-4 text-black">
                    <div className="row align-items-center">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 text-black mt-30 text-md-start text-center">
                            <div className="info text-black ps-md-5">
                                <h1 className="heading text-black">
                                    <span className="small-text">Join us at</span> <br /> Whats
                                    <span className="highlight-text"> NXT</span>
                                </h1>

                                <p className="mt-20 text-black">
                                3X your Business Growth with advanced strategies & case studies leveraging WhatsApp for business</p>
                                <p>
  <FaCalendarAlt className="me-2" /> Wednesday, 9th April, 2025 <br />
  <FaMapPin className="me-2" /> Venue: Eros Hotel, Nehru Place, New Delhi
  <br />
  <FaClock className="me-2" /> Time: 5.30 Onwards
</p>
                                <div className="btns mt-4">
                                    <a
                                        href="https://calendly.com/info-w0m/30min?month=2024-02"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="btn login_button"
                                    > Save your seat
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div>
                                <img
                                    src={EventImage}
                                    className="img-fluid"
                                    alt="eventIMAGE"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="container py-5 my-4 text-black next_event2 ">
                <div className="row align-items-center">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div>
                            <img
                                src={EventImage2}
                                className="img-fluid w-75"
                                alt="eventIMAGE"
                            />
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 text-black mt-30 text-md-start text-center">
                        <div className="info text-black">
                            <h4 className="highlight-text-2">Why WhatsNXT?
                            </h4><p className="text-black">Businesses and customers can explore and take advantage of new prospects within the WhatsApp Business platform by using WhatsNXT as a central location.  
                            </p>
                            <p>The platform, which is enhanced by creative contributions from Meta, encourages cooperation and participation to open up new opportunities for growth and networking.</p>
                            <div className="p-4 text-black">
                                <ul className="list-unstyled m-0">
                                    <li className="d-flex align-items-center mb-3">
                                        <img src={icon1} className="img-fluid  small-icon me-3"  alt="icon-1" />
                                        <span>The latest insights from Meta</span>
                                    </li>
                            
                                    <li className="d-flex align-items-center mb-3">
                                    <img src={icon2} className="img-fluid small-icon me-3"  alt="icon-2" />
                                        <span>A panel discussion with experts</span>
                                    </li>
                                    <li className="d-flex align-items-center mb-3">
                                    <img src={icon3} className="img-fluid  small-icon me-3"  alt="icon-3" />
                                        <span>Networking opportunities</span>
                                    </li>
                                    <li className="d-flex align-items-center mb-3">
                                    <img src={icon4} className="img-fluid  small-icon me-3"  alt="icon-4" />
                                        <span>Success stories</span>
                                    </li>
                                
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="bg-event py-4">
             <div className="container  my-4 text-black">
           <h2 className=" mb-4">Anantya Platform highlights</h2>
           <div className="row row-cols-1 row-cols-md-4 g-4 pb-5 px-md-0 px-3">
            <div className="col">
                <img src={EventImage3} className="img-fluid" alt="" />
            </div>
            <div className="col">
                <img src={EventImage4} className="img-fluid" alt="" />
            </div>
            <div className="col">
                <img src={EventImage5} className="img-fluid" alt="" />
            </div>
            <div className="col">
                <img src={EventImage6} className="img-fluid" alt="" />
            </div>
        
           </div>
           </div>
            </section>
            {/* Consultation Form */}
            <div className="col-lg-12 col-md-12 col-sm-12 d-flex align-items-center justify-content-center pt-md-0 pt-5 my-auto  px-md-2 px-4" id="myStickyForm">
                <div
                    className="px-md-4 px-3 my-5 mx-auto text-center d-flex flex-column align-items-center"
                    style={{
                        boxShadow: "0 34px 54px 0 rgba(9, 48, 85, .1)",
                        maxWidth: "800px", // Change from minWidth to maxWidth
                        width: "100%", // Makes it responsive
                        overflow: "hidden",
                        borderRadius: "30px",
                        background: "#FFF",
                     
                    }}
                >
                    <h4 className="text-center mb-0 text-black">Register Now</h4>

                    <form
                        action="https://formspree.io/f/mdorezev"
                        method="POST"
                        id="myStickyForm"
                        className="form-containers pt-3 text-black pb-4"
                    >
                        <h6 className="text-start mb-2">Contact Details</h6>
                        <div className="form-row">
                            <input
                                type="text"
                                name="firstName"
                                className="form-control"
                                placeholder="Full Name"
                                required
                            />

                        </div>
                        <div className="form-row">
                            <div className="phone-container">
                                <select name="countryCode" className="country-code">
                                    <option value="+91">+91</option>
                                </select>
                                <input
                                    type="text"
                                    name="phone"
                                    className="form-control ms-md-1"
                                    placeholder="WhatsApp Number"
                                />
                            </div>
                            <input
                                type="email"
                                name="email"
                                className="form-control ms-md-3"
                                placeholder="Business Email"
                                required
                            />
                        </div>

                        <h6 className="text-start mb-2  ">Company Details</h6>
                        <div className="form-row">
                            <input type="text" name="companyName" className="form-control" required placeholder="Company Name" />
                            <input type="text" name="website" className="form-control" placeholder="Website" />
                        </div>
                        <div className="form-row">
                            <input type="text" name="industry" className="form-control" placeholder="Industry" required />

                        </div>

                        <div className="form-check d-flex align-items-center">
                            <input className="form-check-input me-3" type="checkbox" id="updates" name="updates" />
                            <label className="form-check-label mt-2" htmlFor="updates" style={{ fontSize: "11px", textAlign: "left" }} required>

                                <span>
                                    By logging in, you agree to the following
                                    <a href="/terms-conditions" className="text-black mx-1">Terms & Conditions</a>
                                    and
                                    <a href="/privacy-policy" className="text-black mx-1">Privacy Policy</a>.
                                </span>
                            </label>
                        </div>

                        {/* ReCAPTCHA */}
                        <div className="recaptcha-container text-start mt-3">
                            <ReCAPTCHA sitekey="6LdvVNIqAAAAAHw2v3cyzKFQbJ7-mYzlBeFz1Ex1" onChange={handleRecaptcha} />
                        </div>

                        {/* Submit Button */}
                        <div className="form-submit mb-3">
                            <input type="submit" className="btn-submit" value="Submit" disabled={!captchaVerified} />
                        </div>
                    </form>
                </div>
            </div>
            <ScrollToTopButton />
            <WhatsAppWidget />
        </>
    );
};

export default WhatsnxtDelhiEvent;
